<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <h1 class="mb-5">List Kategori</h1>
        <v-btn class="mb-5" color="primary" @click="dialogCreate = true">Tambah Kategori</v-btn>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card :loading="loading">
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Nama Kategori</th>
                  <th>Dipakai Artikel</th>
                  <th class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <i  v-if="categories.data.length < 1">Kategori tidak ada</i>
                <tr v-else v-for="(category, i) in categories.data" :key="i">
                  <td>{{ category.name }}</td>
                  <td>{{ category.posts_count }} artikel</td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="editCategoryDialog(category)"><v-icon>edit</v-icon></v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="category.posts_count < 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="red" class="mx-2" v-bind="attrs" v-on="on" @click="deleteCategoryDialog(category)"><v-icon>delete</v-icon></v-btn>
                      </template>
                      <span>Hapus</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="mt-4">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
              <pagination :data="categories" @pagination-change-page="getCategories" align="right"></pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog v-model="dialogEdit" width="500px" max-width="95%" :loading="loading">
        <v-card v-if="formUpdate">
          <v-card-title>Edit Kategori</v-card-title>
          <v-form ref="formUpdate" @submit.prevent="updateCategory()">
            <v-card-text>
              <v-alert type="warning" v-if="updateErrors.length > 0">
                <ul>
                  <li v-for="error in updateErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
                <v-text-field v-model="formUpdate.name" label="Nama Kategori" :rules="rules.required"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">Update</v-btn>
              <v-btn color="grey lighten-2" @click="dialogEdit = false">Batal</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCreate" width="500px" max-width="95%" persistent>
        <v-card v-if="formCreate" :loading="loading"  :disabled="loading">
          <v-card-title>Tambah Kategori Baru</v-card-title>
          <v-form ref="formCreate" @submit.prevent="storeCategory()">
            <v-card-text>
              <v-alert type="warning" v-if="storeErrors.length > 0">
                <ul>
                  <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
                <v-text-field v-model="formCreate.name" label="Nama Kategori" :rules="rules.required"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">Simpan</v-btn>
              <v-btn color="grey lighten-2" @click="dialogCreate = false">Batal</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDestroy" width="500px" max-width="95%">
        <v-card v-if="formDestroy" :loading="loading"  :disabled="loading">
          <v-card-title>Hapus Kategori {{ formDestroy.name }}</v-card-title>
            <v-card-text>
              <v-alert type="warning" v-if="destroyErrors.length > 0">
                <ul>
                  <li v-for="error in destroyErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-btn color="red" dark @click="destroyCategory()">Hapus</v-btn>
              <v-btn color="grey lighten-2" @click="dialogDestroy = false">Batal</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'category.index',
  metaInfo: { title: 'Kategori' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogCreate: false,
      dialogShow: false,
      dialogEdit: false,
      dialogDestroy: false,
      categories: null,
      formCreate: {},
      formUpdate: {},
      formDestroy: {},
      errors: [],
      storeErrors: [],
      updateErrors: [],
      destroyErrors: [],
      messages: [],
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getCategories(page = 1){
      this.loading = true
      await axios.get(`/category?page=${page}`, this.config).then(res => {
        this.categories = res.data.data.categories
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
      this.skeleton = false
    },
    async storeCategory(){
      this.loading = true
      this.storeErrors = []
      await axios.post('/category', this.formCreate, this.config).then(res => {
        this.$toast.success('Kategori berhasil disimpan!')
        this.dialogCreate = false
        this.$refs.formCreate.reset()
        this.$refs.formCreate.resetValidation()
        this.getCategories()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.storeErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateCategory(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/category/${this.formUpdate.id}`, this.formUpdate, this.config).then(res => {
        this.$toast.success('Kategori berhasil diupdate!')
        this.dialogEdit = false
        this.$refs.formUpdate.reset()
        this.$refs.formUpdate.resetValidation()
        this.getCategories()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    async destroyCategory(){
      this.loading = true
      this.destroyErrors = []
      await axios.delete(`/category/${this.formDestroy.id}`, this.config).then(res => {
        this.$toast.warning('Kategori telah dihapus')
        this.dialogDestroy = false
        this.getCategories()
      }).catch(e => {
        this.$toast.warning('Kategori gagal dihapus')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.destroyErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.destroyErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    editCategoryDialog(category){
      this.formUpdate = {...category}
      this.dialogEdit = true
    },
    deleteCategoryDialog(category){
      this.formDestroy = {...category}
      this.dialogDestroy = true
    },
  },
  created(){
    this.getCategories()
  }
}
</script>

<style>

</style>