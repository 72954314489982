<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <h1>List Tiket</h1>
          <v-row>
            <v-col cols="6">
              <v-btn color="light-blue" large dark class="mt-7" @click="dialogSort = true"><v-icon left>filter_list</v-icon> Filter Tiket</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="light-blue" large dark class="mt-7" @click="dialogCreate = true"><v-icon left>add</v-icon> Tambah Tiket</v-btn>
            </v-col>
          </v-row>

          <v-tabs v-model="tab" background-color="grey lighten-3" icons-and-text class="mt-6">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#dinoland">
              Dinoland
              <v-icon>mdi-phone</v-icon>
            </v-tab>

            <v-tab href="#playground">
              Playground
              <v-icon>mdi-heart</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="dinoland">
              <v-card raised>
                <v-card-title>Dinoland</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>Kuota</th>
                        <th>Sisa</th>
                        <th>Booking</th>
                        <th>Jumlah Tiket Dibooking</th>
                        <th>Harga</th>
                        <th class="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <i v-if="dinoland_tickets.length < 1">Tiket tidak ada</i>
                      <tr v-else v-for="(dinoland_ticket, i) in dinoland_tickets" :key="i">
                        <td>{{ moment(dinoland_ticket.date).locale('id').format('LL') }}</td>
                        <td>{{ dinoland_ticket.quota }}</td>
                        <td>{{ dinoland_ticket.remain }}</td>
                        <td>{{ dinoland_ticket.bookings_count }} booking</td>
                        <td>{{ dinoland_ticket.reserved }} tiket</td>
                        <td v-if="dinoland_ticket.price || dinoland_ticket.price > 0">Rp. {{ dinoland_ticket.price.toLocaleString('id') }}</td>
                        <td v-else><i>Harga mengikuti harga utama</i></td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showTicket(dinoland_ticket.id)"><v-icon>visibility</v-icon></v-btn>
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="editTicket(dinoland_ticket)"><v-icon>edit</v-icon></v-btn>
                            </template>
                            <span>Edit Jumlah Tiket</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="green darken-4" class="mx-2" v-bind="attrs" v-on="on" @click="editTicketPrice(dinoland_ticket)"><v-icon>price_change</v-icon></v-btn>
                            </template>
                            <span>Edit Harga Dan Deskripsi</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="red" class="mx-2" v-bind="attrs" v-on="on" @click="dialogDeleteTicket(dinoland_ticket)"><v-icon>delete</v-icon></v-btn>
                            </template>
                            <span>Hapus</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="playground">
              <v-card raised>
                <v-card-title>Playground</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>Kuota</th>
                        <th>Sisa</th>
                        <th>Booking</th>
                        <th>Jumlah Tiket Dibooking</th>
                        <th class="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <i v-if="playground_tickets.length < 1">Tiket tidak ada</i>
                      <tr v-else v-for="(playground_ticket, i) in playground_tickets" :key="i">
                        <td>{{ moment(playground_ticket.date).locale('id').format('LL') }}</td>
                        <td>{{ playground_ticket.quota }}</td>
                        <td>{{ playground_ticket.remain }}</td>
                        <td>{{ playground_ticket.bookings_count }} booking</td>
                        <td>{{ playground_ticket.reserved }} tiket</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showTicket(playground_ticket.id)"><v-icon>visibility</v-icon></v-btn>
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="editTicket(playground_ticket)"><v-icon>edit</v-icon></v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="red" class="mx-2" v-bind="attrs" v-on="on" @click="dialogDeleteTicket(playground_ticket)"><v-icon>delete</v-icon></v-btn>
                            </template>
                            <span>Hapus</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-card>
        <v-dialog v-model="dialogShow">
          <v-card flat v-if="ticketDetail">
            <v-card-title>Detail Tiket {{ ticketDetail.type }}</v-card-title>
            <v-card-text>
              <p>{{ moment(ticketDetail.date).locale('id').format('LL') }}</p>
              <p v-if="ticketDetail.price || ticketDetail.price > 0">Harga : Rp. {{ ticketDetail.price.toLocaleString('id') }}</p>
              <p v-else><i>Harga mengikuti harga utama</i></p>
              <p>Deskripsi : {{ ticketDetail.desc }}</p>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Invoice</th>
                    <th>Jumlah Pesanan</th>
                    <th>Total Harga</th>
                    <th>Status</th>
                    <th class="text-center">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <i v-if="ticketDetail.bookings.length < 1">Booking tidak ada</i>
                  <tr v-else v-for="(booking, i) in ticketDetail.bookings" :key="i">
                    <td>{{ booking.invoice }}</td>
                    <td>{{ booking.amount }}</td>
                    <td>Rp. {{ booking.price.toLocaleString('id') }}</td>
                    <td>{{ booking.status }}</td>
                    <td class="text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on"><v-icon>edit</v-icon></v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-1" dark @click="dialogShow = false">Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCreate" persistent>
          <v-card flat :loading="loading">
            <v-card-title>Tambah Tiket</v-card-title>
            <v-form @submit.prevent="storeTicket()" ref="formStore">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0">
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-date-picker :rules="rules.required" v-model="formStore.date" locale="id" :min="date.start" :max="date.end"></v-date-picker>
                <v-select :rules="rules.required" v-model="formStore.type" :items="tipes" label="Tipe"></v-select>
                <v-text-field label="Kuota Tiket" :rules="rules.required" v-model="formStore.quota" outlined></v-text-field>
                <v-text-field label="Harga (kosongkan apabila harganya mengikuti harga utama)" v-model="formStore.price" outlined></v-text-field>
                <v-textarea v-model="formStore.desc" label="Deskripsi (opsional)" outlined></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogCreate = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" persistent>
          <v-card flat :loading="loading">
            <v-card-title>Edit Jumlah Tiket</v-card-title>
            <v-form @submit.prevent="updateTicket()" ref="formUpdate">
              <v-card-text>
                <v-alert type="warning" v-if="updateErrors.length > 0">
                  <ul>
                    <li v-for="error in updateErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{{ moment(formEdit.date).locale('id').format('LL') }}</td>
                    </tr>
                    <tr>
                      <td>Tipe</td>
                      <td>:</td>
                      <td>{{ formEdit.type }}</td>
                    </tr>
                    <tr>
                      <td>Kuota Tiket</td>
                      <td>:</td>
                      <td>{{ formEdit.quota }}</td>
                    </tr>
                    <tr>
                      <td>Sisa Tiket</td>
                      <td>:</td>
                      <td>{{ formEdit.remain }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <p class="mt-6">Pilih jumlah tiket mau dikurangi atau ditambah</p>
                <v-radio-group v-model="formEdit.changeType">
                  <v-radio value="subs" label="Dikurangi"></v-radio>
                  <v-radio value="add" label="Ditambah"></v-radio>
                </v-radio-group>
                <v-text-field label="Jumlah" :rules="rules.required" v-model="formEdit.newQuota"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Update</v-btn>
                <v-btn @click="dialogEdit = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEditPrice" persistent>
          <v-card flat :loading="loading">
            <v-card-title>Edit Harga & Deskripsi Tiket</v-card-title>
            <v-form @submit.prevent="updateTicketPrice()" ref="formUpdatePrice">
              <v-card-text>
                <v-alert type="warning" v-if="updatePriceErrors.length > 0">
                  <ul>
                    <li v-for="error in updatePriceErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{{ moment(formEditPrice.date).locale('id').format('LL') }}</td>
                    </tr>
                    <tr>
                      <td>Tipe</td>
                      <td>:</td>
                      <td>{{ formEditPrice.type }}</td>
                    </tr>
                    <tr>
                      <td>Kuota Tiket</td>
                      <td>:</td>
                      <td>{{ formEditPrice.quota }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-text-field label="Harga" v-model="formEditPrice.price" outlined></v-text-field>
                <v-textarea v-model="formEditPrice.desc" label="Deskripsi (opsional)" outlined></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Update</v-btn>
                <v-btn @click="dialogEditPrice = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSort" width="400px" max-width="90%">
          <v-card flat :loading="loading">
            <v-card-title>Urutkan Tiket Berdasarkan Tanggal</v-card-title>
            <v-form @submit.prevent="sortTicket()">
              <v-card-text>
                <v-alert type="warning" v-if="sortErrors.length > 0">
                  <ul>
                    <li v-for="error in sortErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-date-picker locale="id" v-model="formSort"></v-date-picker>
                <p>
                  <i>**Tiket yang akan ditampilkan adalah tiket 30 hari dari tanggal yang dipilih</i>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="dialogSort = false; sortErrors = []" dark color="grey">Batal</v-btn>
                <v-btn @click="sortTicket()" color="primary" dark :disabled="formSort === null">Cari</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDestroy" width="400px" max-width="90%">
          <v-card flat v-if="formDestroy" :loading="loading">
            <v-card-title>Hapus Tiket</v-card-title>
            <v-card-text>
              <v-alert type="warning" v-if="destroyErrors.length > 0">
                <ul>
                  <li v-for="error in destroyErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="dialogDestroy = false; destroyErrors = []" dark color="primary">Batal</v-btn>
              <v-btn @click="destroyTicket()" color="red darken-3" dark>Hapus</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ticket.index',
  metaInfo: { title: 'List Tiket' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogCreate: false,
      dialogEdit: false,
      dialogDestroy: false,
      dialogSort: false,
      dialogEditPrice: false,
      moment,
      tipes: ['dinoland', 'playground'],
      errors: [],
      storeErrors: [],
      updateErrors: [],
      updatePriceErrors: [],
      destroyErrors: [],
      sortErrors: [],
      formStore: {
        price: null
      },
      formEdit: {},
      formEditPrice: {},
      formDestroy: {},
      formSort: null,
      date: {
        start: '',
        end: ''
      },
      price: null,
      tab: null,
      dinoland_tickets: null,
      playground_tickets: null,
      ticketDetail: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getTicket(){
      this.loading = true
      await axios.get('/ticket', this.token).then(res => {
        this.dinoland_tickets = res.data.data.dinoland_tickets
        this.playground_tickets = res.data.data.playground_tickets
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async showTicket(id){
      this.loading = true
      await axios.get(`/ticket/${id}`, this.token).then(res => {
        this.ticketDetail = res.data.data.ticket
        this.dialogShow = true
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async sortTicket(){
      this.loading = true
      await axios.get(`/ticket/sort/${this.formSort}`, this.token).then(res => {
        this.dinoland_tickets = res.data.data.dinoland_tickets
        this.playground_tickets = res.data.data.playground_tickets
        this.dialogSort = false
      }).catch(e => {        
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async storeTicket(){
      this.loading = true
      this.storeErrors = []
      if(this.formStore.price <= 0 || this.formStore.price == null){
        delete this.formStore.price
      }
      await axios.post('/ticket', this.formStore, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan tiket!')
        this.dialogCreate = false
        this.$refs.formStore.reset()
        this.$refs.formStore.resetValidation()
        this.getTicket()
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan tiket')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.storeErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateTicket(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/ticket/${this.formEdit.id}`, this.formEdit, this.token).then(res => {
        this.$toast.success('Berhasil update tiket!')
        this.dialogEdit = false
        this.$refs.formUpdate.reset()
        this.$refs.formUpdate.resetValidation()
        this.getTicket()
      }).catch(e => {
        this.$toast.warning('Gagal update harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateTicketPrice(){
      this.loading = true
      this.updatePriceErrors = []
      await axios.put(`/ticket/price/${this.formEditPrice.id}`, this.formEditPrice, this.token).then(res => {
        this.$toast.success('Berhasil update harga dan deskripsi tiket!')
        this.dialogEditPrice = false
        this.$refs.formUpdatePrice.reset()
        this.$refs.formUpdatePrice.resetValidation()
        this.getTicket()
      }).catch(e => {
        this.$toast.warning('Gagal update harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updatePriceErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updatePriceErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async destroyTicket(){
      this.loading = true
      this.destroyErrors = []
      await axios.delete(`/ticket/${this.formDestroy.id}`, this.token).then(res => {
        this.$toast.warning('Tiket telah dihapus')
        this.dialogDestroy = false
        this.getTicket()
      }).catch(e => {
        this.$toast.warning('Gagal hapus tiket')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.destroyErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.destroyErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    editTicket(ticket){
      this.formEdit = {...ticket}
      this.dialogEdit = true
    },
    editTicketPrice(ticket){
      this.formEditPrice = {...ticket}
      this.dialogEditPrice = true
    },
    dialogDeleteTicket(ticket){
      this.formDestroy = {...ticket}
      this.dialogDestroy = true
    },
    generateDate(){
      const date = new Date()
      this.date.start = this.moment(date).format('YYYY-MM-DD')
      this.date.end = this.moment(date).add(60, 'days').format('YYYY-MM-DD')
    },
  },
  created(){
    this.getTicket()
    this.generateDate()
  }
}
</script>