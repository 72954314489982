<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <div>
        <v-container>
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <v-card color="blue darken-3" dark>
            <v-card-title>
              Dashboard Admin
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" lg="12" class="mb-7">
                  <div v-if="price">
                    <p>Harga</p>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <p><b>Dinoland Weekday</b></p>
                        <h3>Rp. {{ price.dinoland.toLocaleString('id') }}</h3>
                      </v-col>
                      <v-col cols="6">
                        <p><b>Dinoland Weekend</b></p>
                        <h3>Rp. {{ price.dinoland_weekend.toLocaleString('id') }}</h3>
                      </v-col>
                      <v-col cols="6">
                        <p><b>Playground Weekday</b></p>
                        <h3>Rp. {{ price.playground.toLocaleString('id') }}</h3>
                      </v-col>
                      <v-col cols="6">
                        <p><b>Playground Weekend</b></p>
                        <h3>Rp. {{ price.playground_weekend.toLocaleString('id') }}</h3>
                      </v-col>
                    </v-row>
                  </div>
                  <i v-else>Harga belum ditentukan, silahkan atur dulu harga</i>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Pengguna</p>
                  <v-divider></v-divider>
                  <h2>{{ users }} orang</h2>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Tiket</p>
                  <v-divider></v-divider>
                  <h2>{{ tickets }}</h2>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Booking</p>
                  <v-divider></v-divider>
                  <h2>{{ bookings }}</h2>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Kategori</p>
                  <v-divider></v-divider>
                  <h2>{{ categories }}</h2>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Artikel</p>
                  <v-divider></v-divider>
                  <h2>{{ posts }}</h2>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="mb-7">
                  <p>Promo</p>
                  <v-divider></v-divider>
                  <h2>{{ promos }}</h2>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'booking.index',
  metaInfo: { title: 'Dashboard' },
  data(){
    return {
      skeleton: true,
      loading: false,
      tickets: 0,
      bookings: 0,
      users: 0,
      promos: 0,
      posts: 0,
      categories: 0,
      price: null,
      errors: [],
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getDashboard(){
      this.loading = true
      await axios.get('/dashboard', this.config).then(res => {
        this.tickets = res.data.data.tickets
        this.bookings = res.data.data.bookings
        this.users = res.data.data.users
        this.promos = res.data.data.promos
        this.categories = res.data.data.categories
        this.posts = res.data.data.posts
        this.price = res.data.data.price
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else if (e.response.status === 500){
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
      this.skeleton = false
    }
  },
  created(){
    this.getDashboard()
  }
}
</script>

<style>

</style>