<template>
  <div>
    <div class="text-center">
      <h1 class="display-4 mt-10">404</h1>
      <p>Page not found :(</p>
      <img src="@/assets/dinosaur-egg.svg" class="mt-14" width="200px">
      <br>
      <v-btn color="primary" class="mt-14" x-large link to="/"> <v-icon>home</v-icon> Kembali</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notfound',
  metaInfo: { title: 'Not Found' },
}
</script>

<style scoped>
.h100{
  height: 90vh;
}
</style>