<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <h1>List Pengguna</h1>
          <v-btn color="light-blue" large dark class="mt-7" @click="dialogCreate = true">Tambah Pengguna</v-btn>
          <v-form class="mt-10" @submit.prevent="searchUser()">
            <v-row>
              <v-col cols="9">
                <v-text-field v-model="formSearch.keyword" label="Masukkan Kata Kunci"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" type="submit"><v-icon left>search</v-icon> Cari</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>Nama</th>
                <th>Email</th>
                <th>Nomor Telpon</th>
                <th>Jabatan</th>
                <th>Status</th>
                <th class="text-center">
                  Aksi <br> 
                  <small>Detail | Edit | Blokir / Buka Blokir</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <i v-if="users.data.length < 1">Pengguna tidak ada</i>
              <tr v-else v-for="(user, i) in users.data" :key="i">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.phone }}</td>
                <td>
                  <v-chip dark v-if="user.role == 'admin'" color="orange darken-1">Admin</v-chip>
                  <v-chip dark v-if="user.role == 'cs'" color="teal darken-1">CS</v-chip>
                  <v-chip dark v-if="user.role == 'user'" color="light-blue">User</v-chip>
                </td>
                <td>
                  <v-chip small dark v-if="user.blocked == '1' || user.blocked == 1 || user.blocked == true" color="red">Diblokir</v-chip>
                  <v-chip small dark v-else color="green">Aktif</v-chip>
                </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showUser(user)"><v-icon>visibility</v-icon></v-btn>
                    </template>
                    <span>Detail</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="editUser(user)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="user.blocked == '1' || user.blocked == 1 || user.blocked == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="light-blue" class="mx-2" v-bind="attrs" v-on="on" @click="blockUserDialog(user)"><v-icon>check</v-icon></v-btn>
                    </template>
                    <span>Buka Blokir</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="red" class="mx-2" v-bind="attrs" v-on="on" @click="blockUserDialog(user)"><v-icon>block</v-icon></v-btn>
                    </template>
                    <span>Blokir</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="mt-4">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
            <pagination v-if="formSearch.keyword.length < 1" :data="users" @pagination-change-page="getUsers" align="right"></pagination>
            <pagination v-else :data="users" @pagination-change-page="searchUser" align="right"></pagination>
          </div>
        </v-card>
        <v-dialog v-model="dialogBlock" width="400px">
          <v-card flat>
            <v-card-title>Blokir Pengguna</v-card-title>
            <v-card-text>
              <p>Yakin {{ formBlock.blocked == 1 || formBlock.blocked == '1' || formBlock.blocked == true ? 'buka blokir' : 'blokir' }} pengguna?</p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-2" @click="dialogBlock = false" dark>Kembali</v-btn>
              <v-btn color="green" v-if="formBlock.blocked == 1 || formBlock.blocked == '1' || formBlock.blocked == true" @click="blockUser()" dark>Buka Blokir</v-btn>
              <v-btn color="red" v-else dark @click="blockUser()">Blokir</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogShow">
          <v-card flat v-if="userDetail">
            <v-card-title>Detail Pengguna</v-card-title>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{{ userDetail.name }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{{ userDetail.email }}</td>
                  </tr>
                  <tr>
                    <td>Nomor Telpon</td>
                    <td>:</td>
                    <td>{{ userDetail.phone }}</td>
                  </tr>
                  <tr>
                    <td>Jabatan</td>
                    <td>:</td>
                    <td>
                      <v-chip dark v-if="userDetail.role == 'admin'" color="orange darken-1">Admin</v-chip>
                      <v-chip dark v-if="userDetail.role == 'cs'" color="teal">CS</v-chip>
                      <v-chip dark v-if="userDetail.role == 'user'" color="light-blue">User</v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>
                      <v-chip small dark v-if="userDetail.blocked == '1' || userDetail.blocked == 1 || userDetail.blocked == true" color="red">Diblokir</v-chip>
                      <v-chip small dark v-else color="green">Aktif</v-chip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-2" @click="dialogShow = false" dark>Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCreate" persistent>
          <v-card flat>
            <v-card-title>Tambah Pengguna Baru</v-card-title>
            <v-form @submit.prevent="storeUser()" ref="formStore">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0">
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-text-field label="Nama" :rules="rules.required" v-model="formStore.name"></v-text-field>
                <v-text-field label="Email" :rules="rules.emailRules" v-model="formStore.email"></v-text-field>
                <v-text-field v-model="formStore.password" :append-icon="showPass ? 'visibility' : 'visibility_off'" :rules="rules.required" :type="showPass ? 'text' : 'password'" name="input-10-1" label="Password" @click:append="showPass = !showPass" ></v-text-field>
                <v-text-field v-model="formStore.password_confirmation" :append-icon="showPassConfirm ? 'visibility' : 'visibility_off'" :rules="rules.required" :type="showPassConfirm ? 'text' : 'password'" name="input-10-1" label="Konfirmasi Password" @click:append="showPassConfirm = !showPassConfirm" ></v-text-field>
                <v-select :rules="rules.required" v-model="formStore.role" :items="roles" label="Jabatan"></v-select>
                <v-text-field label="Nomor Telpon" :rules="rules.required" v-model="formStore.phone"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogCreate = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" persistent>
          <v-card flat v-if="userDetail">
            <v-card-title>Ubah Data Pengguna</v-card-title>
            <v-form @submit.prevent="updateUser()" ref="formEdit">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0">
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>{{ userDetail.name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>:</td>
                      <td>{{ userDetail.email }}</td>
                    </tr>
                    <tr>
                      <td>Jabatan</td>
                      <td>:</td>
                      <td>
                        <v-chip dark v-if="userDetail.role == 'admin'" color="orange darken-1">Admin</v-chip>
                        <v-chip dark v-else color="light-blue">User</v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>:</td>
                      <td>
                        <v-chip small dark v-if="userDetail.blocked == '1' || userDetail.blocked == 1 || userDetail.blocked == true" color="red">Diblokir</v-chip>
                        <v-chip small dark v-else color="green">Aktif</v-chip>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-select class="mt-6" :rules="rules.required" v-model="formEdit.role" :items="roles" label="Jabatan"></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogEdit = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'user.index',
  metaInfo: { title: 'List Pengguna' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogCreate: false,
      dialogEdit: false,
      dialogBlock: false,
      showPass: false,
      showPassConfirm: false,
      moment,
      roles: ['admin', 'user', 'cs'],
      errors: [],
      storeErrors: [],
      updateErrors: [],
      destroyErrors: [],
      formStore: {},
      formEdit: {},
      formBlock: {},
      formSearch: {
        keyword: ''
      },
      users: null,
      userDetail: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
        emailRules: [
          v => !!v || "E-mail harus diisi",
          v => /.+@.+\..+/.test(v) || "Masukkan email yang valid"
        ]
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Pengguna',
          to: '/pengguna',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getUsers(page = 1){
      this.loading = true
      await axios.get(`/user?page=${page}`, this.config).then(res => {
        this.users = res.data.data.users
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              return this.$router.push('/404')
            }
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async searchUser(page = 1){
      this.loading = true
      if(this.formSearch.keyword.length < 1){
        this.getUsers()
      }else{
        await axios.get(`/user/search/${this.formSearch.keyword}?page=${page}`, this.config).then(res => {
          this.users = res.data.data.users
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if (e.response.status === 500){
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }else{
              if(e.response.data){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
        this.loading = false
      }
    },
    async storeUser(){
      this.loading = true
      this.storeErrors = []
      await axios.post('/user', this.formStore, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan pengguna!')
        this.dialogCreate = false
        this.$refs.formStore.reset()
        this.$refs.formStore.resetValidation()
        this.getUsers()
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan pengguna')
        if(e.response){
          console.log(e.response);
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateUser(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/user/${this.formEdit.id}`, this.formEdit, this.token).then(res => {
        this.$toast.success('Berhasil update pengguna!')
        this.dialogEdit = false
        this.$refs.formEdit.reset()
        this.$refs.formEdit.resetValidation()
        this.getUsers()
      }).catch(e => {
        this.$toast.warning('Gagal update pengguna')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async blockUser(){
      this.loading = true
      this.errors = []
      await axios.put(`/user/block`, this.formBlock, this.token).then(res => {
        const blocked  = res.data.data.user.blocked
        if(blocked == 1 || blocked == '1' || blocked == true){
          this.$toast.warning('Pengguna berhasil diblokir!')
        }else{
          this.$toast.success('Pengguna berhasil dibuka blokir!')
        }
        this.dialogBlock = false
        this.getUsers()
      }).catch(e => {
        this.$toast.warning('Gagal update pengguna')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    showUser(user){
        this.userDetail = {...user}
        this.dialogShow = true
    },
    blockUserDialog(user){
      this.formBlock.id = user.id
      this.formBlock.blocked = user.blocked
      this.dialogBlock = true
    },
    editUser(user){
      this.formEdit.role = user.role
      this.formEdit.id = user.id
      this.userDetail = {...user}
      this.dialogEdit = true
    },
  },
  created(){
    this.getUsers()
  }
}
</script>