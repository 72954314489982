<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <h1 class="mb-5">List Artikel</h1>
        <v-btn class="mb-5" link to="/artikel/tambah" color="primary">Tambah Artikel</v-btn>
        <v-form ref="formSearch" @submit.prevent="searchPosts()">
          <v-row>
              <v-col cols="8">
                <v-text-field :loading="loading" v-model="formSearch.keywords" label="Masukkan Keyword Judul Artikel"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn type="submit" color="primary" :loading="loading">Cari</v-btn>
              </v-col>
          </v-row>
        </v-form>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card :loading="loading" :disabled="loading">
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Gambar</th>
                  <th>Judul Artikel</th>
                  <th>Status Publish</th>
                  <th class="text-center">Aksi</th>
                </tr>
              </thead>
              <i v-if="posts.data.length < 1">Artikel tidak ada</i>
              <tbody v-else>
                <tr v-for="(post, i) in posts.data" :key="i">
                  <td>
                    <v-img width="100px" height="60px" :src="post.image"></v-img>
                  </td>
                  <td>{{ post.title }}</td>
                  <td>
                    <v-chip color="green" dark v-if="post.publish == true || post.publish == true || post.publish == 1 || post.publish == '1'">Aktif</v-chip>
                    <v-chip v-else color="grey lighten-2">Tidak Aktif</v-chip>
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showPost(post)"><v-icon>visibility</v-icon></v-btn>
                      </template>
                      <span>Detail</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="indigo" class="mx-2" v-bind="attrs" v-on="on" light :to="`/artikel/edit/${post.id}/${post.slug}`"><v-icon>edit</v-icon></v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="post.publish == true || post.publish == true || post.publish == 1 || post.publish == '1'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab x-small color="grey lighten-2" class="mx-2" v-bind="attrs" v-on="on" @click="showPublishDialog(post)"><v-icon>close</v-icon></v-btn>
                      </template>
                      <span>Non Aktifkan</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="showPublishDialog(post)"><v-icon>check</v-icon></v-btn>
                      </template>
                      <span>Aktifkan</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="red" class="mx-2" v-bind="attrs" v-on="on" @click="destroyPost(post)"><v-icon>delete</v-icon></v-btn>
                      </template>
                      <span>Hapus Artikel</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
              <div class="mt-4">
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
                <pagination v-if="pagination == 'index'" :data="posts" @pagination-change-page="getPosts" align="right"></pagination>
                <pagination v-else :data="posts" @pagination-change-page="searchPosts" align="right"></pagination>
              </div>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog v-model="dialogShow" width="90%">
        <v-card v-if="postDetail">
          <v-card-text>
            <v-img width="300px" :src="postDetail.image"></v-img>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Judul</td>
                  <td>:</td>
                  <td>{{ postDetail.title }}</td>
                </tr>
                <tr>
                  <td>Meta Desc</td>
                  <td>:</td>
                  <td>{{ postDetail.desc }}</td>
                </tr>
                <tr>
                  <td>Meta Keywords</td>
                  <td>:</td>
                  <td>{{ postDetail.keywords }}</td>
                </tr>
                <tr>
                  <td>Status Publish</td>
                  <td>:</td>
                  <td>
                    <v-chip color="green" dark v-if="postDetail.publish == true || postDetail.publish == 1 || postDetail.publish == '1'">Aktif</v-chip>
                    <v-chip v-else color="grey lighten-2">Tidak Aktif</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <h3 class="text-center mt-4">Isi</h3>
            <div v-html="postDetail.content"></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" @click="dialogShow = false" dark>Kembali</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPublish" width="340px" max-width="95%">
        <v-card v-if="formPublish" :loading="loading">
          <v-card-title>
            <p>{{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'Nonaktifkan ' : 'Aktifkan '}} Promo</p>
          </v-card-title>
          <v-card-text>
            <p>Yakin {{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'nonaktifkan ' : 'aktifkan '}} promo ini?</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" @click="dialogPublish = false" dark>Kembali</v-btn>
            <v-btn color="primary" @click.prevent="publishPost()" dark>{{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'Nonaktifkan ' : 'Aktifkan '}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDestroy" width="540px" max-width="95%">
        <v-card v-if="formDestroy" :loading="loading">
          <v-card-title>
            Hapus Artikel
          </v-card-title>
          <v-card-text>
            <p>Yakin hapus artikel: "{{ formDestroy.title }}" ?</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" @click="dialogPublish = false" dark>Kembali</v-btn>
            <v-btn color="red" @click.prevent="destroyPost()" dark>Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'post.index',
  metaInfo: { title: 'List Artikel' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogPublish: false,
      dialogDestroy: false,
      posts: null,
      postDetail: null,
      pagination: 'index',
      formPublish: {},
      formDestroy: {},
      formSearch: {keywords: ''},
      errors: [],
      messages: [],
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getPosts(page = 1){
      this.loading = true
      this.pagination = 'index'
      await axios.get(`/post?page=${page}`, this.config).then(res => {
        this.posts = res.data.data.posts
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
      this.skeleton = false
    },
    async searchPosts(page = 1){
      this.loading = true
      if(this.formSearch.keywords.length < 1){
        this.getPosts()
      }else{
        this.pagination = 'search'
        await axios.get(`/post/search/${this.formSearch.keywords}?page=${page}`, this.config).then(res => {
          this.posts = res.data.data.posts
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if(e.response.status === 422){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
        window.scrollTo(0, 0);
        this.loading = false
        this.skeleton = false
      }
    },
    async publishPost(){
      this.loading = true
      await axios.put(`/post/publish/${this.formPublish.id}`).then(res => {
        const publish = res.data.data.post.publish 
        let message = ''
        if(publish == true || publish == true || publish == 1 || publish == '1'){
          message = 'Berhasil mengatifkan artikel'
        }else{
          message = 'Berhasil menonaktifkan artikel'
        }
        this.$toast.success(message)
        this.getPosts()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
          console.log(e);
        }
      })
      this.dialogPublish = false
      this.loading = false
    },
    async destroyPost(){
      this.loading = true
      await axios.delete(`/post/${this.formDestroy.id}`).then(res => {
        this.$toast.warning('Artikel berhasil dihapus')
        this.getPosts()
      }).catch(e => {
        this.$toast.warning('Artikel gagal dihapus')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
          console.log(e);
        }
      })
      this.dialogDestroy = false
      this.loading = false
    },
    showPost(post){
      this.postDetail = {...post}
      this.dialogShow = true
    },
    showPublishDialog(post){
      this.formPublish = {...post}
      this.dialogPublish = true
    },

  },
  created(){
    this.getPosts()
  }
}
</script>

<style>

</style>