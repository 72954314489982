import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '../views/auth/Login'
import UserIndex from '../views/user/Index'
import UserSetting from '../views/user/Setting'
import DashboardIndex from '../views/admin/dashboard/Index'
import PriceIndex from '../views/admin/price/Index'
import TicketIndex from '../views/admin/ticket/Index'
import BookingIndex from '../views/admin/booking/Index'
import BookingCreate from '../views/admin/booking/Create'
import BookingStore from '../views/admin/booking/Store'
import BookingShow from '../views/admin/booking/Show'
import BookingEdit from '../views/admin/booking/Edit'
import BookingConfirm from '../views/admin/booking/Confirm'
import ImageIndex from '../views/admin/image/Index'
import PromoIndex from '../views/admin/promo/Index'
import PromoCreate from '../views/admin/promo/Create'
import PromoEdit from '../views/admin/promo/Edit'
import CategoryIndex from '../views/admin/category/Index'
import PostIndex from '../views/admin/post/Index'
import PostCreate from '../views/admin/post/Create'
import PostEdit from '../views/admin/post/Edit'
import MemberIndex from '../views/admin/member/Index'

import DashboardCsIndex from '../views/cs/dashboard/Index'
import BookingCsIndex from '../views/cs/booking/Index'
import BookingCsCreate from '../views/cs/booking/Create'
import BookingCsStore from '../views/cs/booking/Store'
import BookingCsShow from '../views/cs/booking/Show'
import BookingCsEdit from '../views/cs/booking/Edit'
import BookingCsConfirm from '../views/cs/booking/Confirm'

import NotFound from '../views/NotFound'

Vue.use(VueRouter)

// middleware 
const isAdmin = (to, from, next) => {
  if (store.getters['auth/isAdmin']) {
    return next()
  } else {
    return next({
      name: "cs.dashboard",
    })
  }
}
const isCs = (to, from, next) => {
  if (store.getters['auth/isCs']) {
    return next()
  } else {
    return next({
      name: "login",
    })
  }
}
const isNotLoggedIn = (to, from, next) => {
  if (!store.getters['auth/isAdmin']) {
    return next()
  } else {
    return next({
      name: "admin.dashboard",
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: isNotLoggedIn
  },
  {
    path: '/dashboard',
    name: 'admin.dashboard',
    component: DashboardIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/list-harga',
    name: 'admin.price',
    component: PriceIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/tiket',
    name: 'admin.ticket.index',
    component: TicketIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/booking',
    name: 'admin.booking.index',
    component: BookingIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/booking/tambah',
    name: 'admin.booking.create',
    component: BookingCreate,
    beforeEnter: isAdmin
  },
  {
    path: '/booking/detail/:id/:invoice',
    name: 'admin.booking.show',
    component: BookingShow,
    beforeEnter: isAdmin
  },
  {
    path: '/booking/tambah/:id/:type/:date',
    name: 'admin.booking.store',
    component: BookingStore,
    beforeEnter: isAdmin
  },
  {
    path: '/booking/edit/:id/:invoice',
    name: 'admin.booking.edit',
    component: BookingEdit,
    beforeEnter: isAdmin
  },
  {
    path: '/booking/konfirmasi/:id/:invoice',
    name: 'admin.booking.confirm',
    component: BookingConfirm,
    beforeEnter: isAdmin
  },
  // user
  {
    path: '/pengguna',
    name: 'admin.user.index',
    component: UserIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/pengguna/pengaturan-akun',
    name: 'admin.user.setting',
    component: UserSetting,
    beforeEnter: isAdmin
  },
  // image
  {
    path: '/gambar',
    name: 'admin.image.index',
    component: ImageIndex,
    beforeEnter: isAdmin
  },
  // promo
  {
    path: '/promo',
    name: 'admin.promo.index',
    component: PromoIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/promo/tambah',
    name: 'admin.promo.create',
    component: PromoCreate,
    beforeEnter: isAdmin
  },
  {
    path: '/promo/edit/:id/:slug',
    name: 'admin.promo.edit',
    component: PromoEdit,
    beforeEnter: isAdmin
  },
  // category
  {
    path: '/kategori',
    name: 'admin.category.index',
    component: CategoryIndex,
    beforeEnter: isAdmin
  },
  // post
  {
    path: '/artikel',
    name: 'admin.post.index',
    component: PostIndex,
    beforeEnter: isAdmin
  },
  {
    path: '/artikel/tambah',
    name: 'admin.post.create',
    component: PostCreate,
    beforeEnter: isAdmin
  },
  {
    path: '/artikel/edit/:id/:slug',
    name: 'admin.post.edit',
    component: PostEdit,
    beforeEnter: isAdmin
  },
  {
    path: '/member',
    name: 'admin.member.index',
    component: MemberIndex,
    beforeEnter: isAdmin
  },
  // CS
  // booking
  
  {
    path: '/cs/dashboard',
    name: 'cs.dashboard',
    component: DashboardCsIndex,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking',
    name: 'cs.booking.index',
    component: BookingCsIndex,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking/tambah',
    name: 'cs.booking.create',
    component: BookingCsCreate,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking/detail/:id/:invoice',
    name: 'cs.booking.show',
    component: BookingCsShow,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking/tambah/:id/:type/:date',
    name: 'cs.booking.store',
    component: BookingCsStore,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking/edit/:id/:invoice',
    name: 'cs.booking.edit',
    component: BookingCsEdit,
    beforeEnter: isCs
  },
  {
    path: '/cs/booking/konfirmasi/:id/:invoice',
    name: 'cs.booking.confirm',
    component: BookingCsConfirm,
    beforeEnter: isCs
  },

  // 404 NOT FOUND
  { 
    path: "/404",
    name: 'PageNotFound.404',
    component: NotFound
  },
  { 
    path: "*",
    name: 'PageNotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
