<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>

          <v-card v-if="!price" :loading="loading">
            <v-card-title>Tambahkan Harga</v-card-title>
            <v-form @submit.prevent="storePrice()">
              <v-card-text>
                  <v-text-field label="Harga Dinoland Weekday" :rules="rules.required" v-model="form.dinoland"></v-text-field>
                  <v-text-field label="Harga Dinoland Weekend" :rules="rules.required" v-model="form.dinoland_weekend"></v-text-field>
                  <v-text-field label="Harga Playground Weekday" :rules="rules.required" v-model="form.playground"></v-text-field>
                  <v-text-field label="Harga Playground Weekend" :rules="rules.required" v-model="form.playground_weekend"></v-text-field>
                  
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

          <div v-else>
            <h1>List Harga</h1>
            <v-row>
              <v-col lg="6" cols="12">
                <v-card shaped raised>
                  <v-card-title>Dinoland Weekday</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>Harga</td>
                          <td>:</td>
                          <td><b>Rp. {{ price.dinoland.toLocaleString('id-ID') }}</b></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col lg="6" cols="12">
                <v-card shaped raised>
                  <v-card-title>Dinoland Weekend</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>Harga</td>
                          <td>:</td>
                          <td><b>Rp. {{ price.dinoland_weekend.toLocaleString('id-ID') }}</b></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col lg="6" cols="12">
                <v-card shaped raised>
                  <v-card-title>Playground Weekday</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>Harga</td>
                          <td>:</td>
                          <td><b>Rp. {{ price.playground.toLocaleString('id-ID') }}</b></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col lg="6" cols="12">
                <v-card shaped raised>
                  <v-card-title>Playground Weekend</v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>Harga</td>
                          <td>:</td>
                          <td><b>Rp. {{ price.playground_weekend.toLocaleString('id-ID') }}</b></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-btn large block color="light-green darken-2" class="mt-8" dark @click="editPrice()"><v-icon left>border_color</v-icon> Edit Harga</v-btn>
          </div>
        </v-card>

        <v-dialog v-model="dialogEdit" persistent>
          <v-card flat :loading="loading">
            <v-card-title>Edit Harga</v-card-title>
            <v-form @submit.prevent="updatePrice()">
              <v-card-text>
                <v-alert type="warning" v-if="updateErrors.length > 0">
                  <ul>
                    <li v-for="error in updateErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-text-field label="Harga Dinoland Weekday" :rules="rules.required" v-model="formEdit.dinoland"></v-text-field>
                <v-text-field label="Harga Dinoland Weekend" :rules="rules.required" v-model="formEdit.dinoland_weekend"></v-text-field>
                <v-text-field label="Harga Playground Weekday" :rules="rules.required" v-model="formEdit.playground"></v-text-field>
                <v-text-field label="Harga Playground Weekend" :rules="rules.required" v-model="formEdit.playground_weekend"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Update</v-btn>
                <v-btn @click="dialogEdit = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'price.index',
  metaInfo: { title: 'List Harga' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogEdit: false,
      errors: [],
      updateErrors: [],
      form: {},
      formEdit: {},
      price: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getPrice(){
      this.loading = true
      await axios.get('/price', this.token).then(res => {
        this.price = res.data.data.price
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async storePrice(){
      this.loading = true
      this.errors = []
      await axios.post('/price', this.form, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan harga!')
        this.price = res.data.data.price
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    editPrice(){
      this.formEdit = {...this.price}
      this.dialogEdit = true
    },
    async updatePrice(){
      this.loading = true
      this.updateErrors = []
      await axios.put('/price', this.formEdit, this.token).then(res => {
        this.$toast.success('Berhasil update harga!')
        this.price = res.data.data.price
        this.dialogEdit = false
      }).catch(e => {
        this.$toast.warning('Gagal update harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            if(e.response.data){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
  },
  created(){
    this.getPrice()
  }
}
</script>