<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <h1>List Booking</h1>
          <v-row class="mt-7">
            <v-col cols="12" lg="4">
              <v-btn color="light-blue" dark @click="dialogSort = true"><v-icon>date_range</v-icon> Filter Tanggal</v-btn>
            </v-col>
            <v-col cols="12" lg="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="light-blue" dark v-bind="attrs" v-on="on" ><v-icon>sort</v-icon> Filter Status </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="setFilter('pending')">
                    <v-list-item-title>Pending</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="setFilter('finish')">
                    <v-list-item-title>Finish</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="setFilter('cancel')">
                    <v-list-item-title>Cancel</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="setFilter('used')">
                    <v-list-item-title>Used</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4">
              <v-btn color="light-blue" dark link :to="{ name: 'cs.booking.create' }"><v-icon>add</v-icon> Tambah Booking</v-btn>
            </v-col>
          </v-row>
          <v-form class="mt-10" @submit.prevent="searchBooking()">
            <v-row>
              <v-col cols="8" lg="9">
                <v-text-field v-model="formSearch.keyword" label="Masukkan Invoice atau Email Customer atau Token QR Code"></v-text-field>
              </v-col>
              <v-col cols="4" lg="3">
                <v-btn color="primary" type="submit"><v-icon left>search</v-icon> Cari</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Tanggal</th>
                <th>Email</th>
                <th>Jumlah Tiket</th>
                <th>Total Harga</th>
                <th>Status</th>
                <th class="text-center">Aksi <br> <small>Detail | Konfirmasi Pembayaran / Gunakan Tiket</small></th>
              </tr>
            </thead>
            <tbody>
              <i v-if="bookings.data.length < 1">Booking tidak ada</i>
              <tr v-else v-for="(booking, i) in bookings.data" :key="i">
                <td>{{ booking.invoice }}</td>
                <td>{{ moment(booking.date).locale('id').format('LL') }}</td>
                <td>{{ booking.user.email }}</td>
                <td>{{ booking.amount }} tiket</td>
                <td>Rp. {{ booking.price.toLocaleString('id') }}</td>
                <td>
                  <v-chip v-if="booking.status === 'pending'" color="light-blue lighten-1" dark small>Pending</v-chip>
                  <v-chip v-if="booking.status === 'finish'" color="green lighten-1" dark small>Dikonfirmasi</v-chip>
                  <v-chip v-if="booking.status === 'cancel'" color="red" dark small>Dibatalkan</v-chip>
                  <v-chip v-if="booking.status === 'used'" color="blue-grey darken-3" dark x-small>Sudah Digunakan</v-chip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showBooking(booking.id)"><v-icon>visibility</v-icon></v-btn>
                    </template>
                    <span>Detail</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="booking.status == 'pending'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="teal" class="mx-2" v-bind="attrs" v-on="on" link :to="`/cs/booking/konfirmasi/${booking.id}/${booking.invoice}`"><v-icon>price_check</v-icon></v-btn>
                    </template>
                    <span>Konfirmasi Pembayaran</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="booking.status == 'finish'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="light-green" class="mx-2" v-bind="attrs" v-on="on" @click="showDialogUse(booking)"><v-icon>verified</v-icon></v-btn>
                    </template>
                    <span>Gunakan Tiket</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="mt-4">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
            <pagination v-if="pagination == 'index'" :data="bookings" @pagination-change-page="getBooking" align="right"></pagination>
            <pagination v-if="pagination == 'search'" :data="bookings" @pagination-change-page="searchBooking" align="right"></pagination>
            <pagination v-if="pagination == 'sort'" :data="bookings" @pagination-change-page="sortBooking" align="right"></pagination>
            <pagination v-if="pagination == 'status'" :data="bookings" @pagination-change-page="filterBooking" align="left"></pagination>
          </div>
        </v-card>
        <v-dialog v-model="dialogShow">
          <v-card flat v-if="bookingDetail">
            <v-card-title>Detail Booking</v-card-title>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>Invoice</td>
                    <td>:</td>
                    <td>{{ bookingDetail.invoice }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal</td>
                    <td>:</td>
                    <td>{{ moment(bookingDetail.date).locale('id').format('LL') }}</td>
                  </tr>
                  <tr>
                    <td>Pembuat Booking</td>
                    <td>:</td>
                    <td>{{ bookingDetail.user.name  }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{{ bookingDetail.user.email }}</td>
                  </tr>
                  <tr>
                    <td>Jumlah Tiket</td>
                    <td>:</td>
                    <td>{{ bookingDetail.amount }}</td>
                  </tr>
                  <tr>
                    <td>Total Harga</td>
                    <td>:</td>
                    <td>Rp. {{ bookingDetail.price.toLocaleString('id') }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>:</td>
                    <td>
                      <v-chip v-if="bookingDetail.status === 'pending'" color="light-blue lighten-1" dark>Pending</v-chip>
                      <v-chip v-if="bookingDetail.status === 'finish'" color="green lighten-1" dark>Pending</v-chip>
                      <v-chip v-if="bookingDetail.status === 'cancel'" color="green lighten-1" dark>Dibatalkan</v-chip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="bookingDetail.status == 'finish'">
                  <tr>
                    <td>Pembayaran</td>
                    <td>:</td>
                    <td>{{ bookingDetail.payment }}</td>
                  </tr>
                  <tr>
                    <td>Token</td>
                    <td>:</td>
                    <td>{{ bookingDetail.token }}</td>
                  </tr>
                  <tr>
                    <td>Qr Code</td>
                    <td>:</td>
                    <td>
                      <vrcode :value="bookingDetail.token" :options="{ size: 150 }"></vrcode>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-btn link :to="`/cs/booking/detail/${bookingDetail.id}/${bookingDetail.invoice}`" color="green" dark>Lihat Detail Booking</v-btn>
              <v-btn color="grey darken-2" @click="dialogShow = false" dark>Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCreate">
          <v-card flat>
            <v-card-title>Tambah Booking</v-card-title>
            <v-form @submit.prevent="storeBooking()">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0">
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-select :rules="rules.required" v-model="formStore.type" :items="types" label="Tipe"></v-select>
                <v-text-field label="Kuota Tiket" :rules="rules.required" v-model="formStore.quota"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogCreate = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSort" width="400px" max-width="90%">
          <v-card flat>
            <v-card-title>Urutkan Tiket Berdasarkan Tanggal</v-card-title>
            <v-form @submit.prevent="sortBooking()">
              <v-card-text>
                <v-alert type="warning" v-if="sortErrors.length > 0">
                  <ul>
                    <li v-for="error in sortErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-date-picker locale="id" v-model="formSort"></v-date-picker>
                <p>
                  <i>**Booking yang akan ditampilkan adalah tiket 30 hari dari tanggal yang dipilih</i>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="dialogSort = false; sortErrors = []" dark color="grey">Batal</v-btn>
                <v-btn @click="sortBooking()" color="primary" dark :disabled="formSort === null">Cari</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogUse" width="400px" max-width="90%">
          <v-card flat>
            <v-card-title>Gunakan Tiket Dengan Invoice : <br> <u>{{ formUse.invoice }}</u> ?</v-card-title>
            <v-form @submit.prevent="useBooking()">
              <v-card-text>
                <v-alert type="warning" v-if="useErrors.length > 0">
                  <ul>
                    <li v-for="error in useErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <p>Anda yakin tiket ini mau digunakan?</p>
                <ul>
                  <li>
                    <p><b><i>Tiket hanya bisa digunakan sekali!</i></b></p>
                  </li>
                  <li>
                    <p><b><i>Tidak bisa masuk 2 kali</i></b></p>
                  </li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="dialogUse = false; sortErrors = []" dark color="grey">Batal</v-btn>
                <v-btn type="submit" color="primary">Gunakan Tiket</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import vrcode from '@ispa.io/vrcode'

export default {
  name: 'cs.booking.index',
  metaInfo: { title: 'List Booking' },
  components: { vrcode },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogCreate: false,
      dialogSort: false,
      dialogUse: false,
      moment,
      types: ['dinoland', 'playground'],
      errors: [],
      storeErrors: [],
      updateErrors: [],
      destroyErrors: [],
      sortErrors: [],
      useErrors: [],
      formStore: {},
      formEdit: {},
      formSort: null,
      formDestroy: {},
      formUse: {},
      formFilter: {
        status: ''
      },
      formSearch: {
        keyword: 'INV-GD-DINOLAND-'
      },
      bookings: null,
      bookingDetail: null,
      pagination: 'index',
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/cs/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Booking',
          to: '/cs/booking',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getBooking(page = 1){
      this.loading = true
      this.pagination = 'index'
      await axios.get(`/cs/booking?page=${page}`, this.config).then(res => {
        this.bookings = res.data.data.bookings
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async searchBooking(page = 1){
      this.loading = true
      this.pagination = 'search'
      if(this.formSearch.keyword.length < 1){
        this.getBooking()
      }else{
        await axios.get(`/cs/booking/search/${this.formSearch.keyword}?page=${page}`, this.config).then(res => {
          this.bookings = res.data.data.bookings
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if(e.response.status === 422){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
      }
      window.scrollTo(0, 0);
      this.loading = false
    },
    async showBooking(id){
      this.loading = true
      await axios.get(`/cs/booking/${id}`, this.config).then(res => {
        this.bookingDetail = res.data.data.booking
        this.dialogShow = true
      }).catch(e => {
       if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    async sortBooking(page = 1){
      this.loading = true
      this.pagination = 'sort'
      await axios.get(`/cs/booking/sort/${this.formSort}?page=${page}`, this.config).then(res => {
        this.bookings = res.data.data.bookings
        this.dialogSort = false
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async filterBooking(page = 1){
      this.loading = true
      this.pagination = 'status'
        await axios.get(`/cs/booking/sort/status/${this.formFilter.status}?page=${page}`, this.config).then(res => {
        this.bookings = res.data.data.bookings
        this.dialogSort = false
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    async storeBooking(){
      this.loading = true
      this.storeErrors = []
      await axios.post('/cs/booking', this.formStore, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan tiket!')
        this.dialogCreate = false
        this.formStore = {}
        this.getBooking()
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan tiket')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.storeErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateBooking(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/cs/ticket/${this.formEdit.id}`, this.formEdit, this.token).then(res => {
        this.$toast.success('Berhasil update tiket!')
        this.dialogEdit = false
        this.getBooking()
      }).catch(e => {
        this.$toast.warning('Gagal update harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async useBooking(){
      this.loading = true
      await axios.put(`/cs/booking/confirm/use/${this.formUse.id}`, this.formUse, this.token).then(res => {
        this.$toast.success('Status booking berhasil diupdate menjadi : sudah digunakan!')
        this.dialogUse = false
        this.getBooking()
      }).catch(e => {
        this.$toast.warning('Gagal update harga')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.useErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.useErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.useErrors = []
      this.loading = false
    },
    setFilter(status){
      this.formFilter.status = status
      this.filterBooking()
    },
    editTicket(ticket){
      this.formEdit = {...ticket}
      this.dialogEdit = true
    },
    dialogDeleteBooking(ticket){
      this.formDestroy = {...ticket}
      this.dialogDestroy = true
    },
    showDialogUse(ticket){
      this.formUse = {...ticket}
      this.dialogUse = true
    },
  },
  created(){
    this.getBooking()
  }
}
</script>