<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <h1>List Member</h1>
          <v-btn color="light-blue" large dark class="mt-7" @click="dialogCreate = true">Tambah Member</v-btn>
          <p class="mt-5">Filter Status Digunakan</p>
          <v-row>
            <v-col cols="6">
              <v-btn @click="type = 1; showUsed(1)" :disabled="type == 1 || type == true || type == '1'" color="grey lighten-2">Digunakan</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="type = 0; showUsed(0)" :disabled="type == 0 || type == false || type == '0'" color="grey lighten-2">Belum Digunakan</v-btn>
            </v-col>
          </v-row>
          <v-form class="mt-10" @submit.prevent="searchMember()">
            <v-row>
              <v-col cols="9">
                <v-text-field v-model="formSearch.keyword" label="Masukkan Kata Kunci"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" type="submit"><v-icon left>search</v-icon> Cari</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>Member ID</th>
                <th>Digunakan</th>
                <th>Nama</th>
                <th>Email</th>
                <th>QR Code</th>
                <th>Tanggal Kadaluarsa</th>
                <th class="text-center">
                  Aksi <br> 
                  <small>Detail | Edit / Gunakan</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <i v-if="members.data.length < 1">Member tidak ada</i>
              <tr v-else v-for="(member, i) in members.data" :key="i">
                <td>{{ member.member_id }}</td>
                <td v-if="member.used == 1 || member.used == '1' ||member.used == true "><v-chip color="green" dark>Digunakan</v-chip></td>
                <td v-else><v-chip color="grey lighten-2">Belum Digunakan</v-chip></td>
                <td>{{ member.name ? member.name : '-' }}</td>
                <td>{{ member.email ? member.email : '-' }}</td>
                <td>
                  <qr-code :text="member.member_id" :size="150"></qr-code>
                </td>
                <td v-if="member.expired">{{ moment(member.expired).locale('id').format('LL') }}</td>
                <td v-else>-</td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showMember(member)"><v-icon>visibility</v-icon></v-btn>
                    </template>
                    <span>Detail</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="member.used == 1 || member.used == '1' || member.used == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="lime darken-1" class="mx-2" v-bind="attrs" v-on="on" @click="editMember(member)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="useMember(member)"><v-icon>check</v-icon></v-btn>
                    </template>
                    <span>Gunakan Member</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="mt-4">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
            <pagination v-if="pagination == 'index'" :data="members" @pagination-change-page="getMembers" align="right"></pagination>
            <pagination v-if="pagination == 'search'" :data="members" @pagination-change-page="searchMember" align="right"></pagination>
            <pagination v-if="pagination == 'used'" :data="members" @pagination-change-page="showUsed" align="right"></pagination>
          </div>
        </v-card>
        <v-dialog v-model="dialogShow">
          <v-card flat v-if="memberDetail">
            <v-card-title>Detail Member</v-card-title>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>Member ID</td>
                    <td>:</td>
                    <td>{{ memberDetail.member_id }}</td>
                  </tr>
                  <tr>
                    <td>Digunakan</td>
                    <td>:</td>
                    <td v-if="memberDetail.used == 1 || memberDetail.used == '1' ||memberDetail.used == true "><v-chip color="green" dark>Digunakan</v-chip></td>
                    <td v-else><v-chip color="grey lighten-2">Belum Digunakan</v-chip></td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{{ memberDetail.name ? memberDetail.name : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{{ memberDetail.email ? memberDetail.email : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Nomor Telpon</td>
                    <td>:</td>
                    <td>{{ memberDetail.phone ? memberDetail.phone : '-' }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Kadaluarsa Member</td>
                    <td>:</td>
                    <td v-if="memberDetail.expired">{{ moment(memberDetail.expired).locale('id').format('LL') }}</td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <p class="text-center">
                <qr-code :text="memberDetail.member_id"></qr-code>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey darken-2" @click="dialogShow = false" dark>Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCreate" persistent>
          <v-card flat :loading="loading">
            <v-card-title>Generate Member Baru</v-card-title>
            <v-form @submit.prevent="storeMember()" ref="formStore">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0" dismissible>
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-text-field label="Jumlah Member Baru (maks 24)" :rules="rules.required" v-model="formStore.qty"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogCreate = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" persistent>
          <v-card flat v-if="formEdit" :loading="loading">
            <v-card-title>Ubah Data Member</v-card-title>
            <v-form @submit.prevent="updateMember()" ref="formEdit">
              <v-card-text>
                <v-alert type="warning" v-if="updateErrors.length > 0">
                  <ul>
                    <li v-for="error in updateErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>{{ formEdit.name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>:</td>
                      <td>{{ formEdit.email }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-text-field label="Nama" :rules="rules.required" v-model="formEdit.name"></v-text-field>
                <v-text-field label="Nomor Telpon / WA" :rules="rules.required" v-model="formEdit.phone"></v-text-field>
                <p>Tanggal kadaluarsa membership</p>
                <v-date-picker v-model="formEdit.expired"></v-date-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Simpan</v-btn>
                <v-btn @click="dialogEdit = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogUse" persistent>
          <v-card flat v-if="formUse">
            <v-card-title>Gunakan Member</v-card-title>
            <v-form @submit.prevent="updateUseMember()" ref="formUse">
              <v-card-text>
                <v-alert type="warning" v-if="useErrors.length > 0">
                  <ul>
                    <li v-for="error in useErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-text-field label="Nama" :rules="rules.required" v-model="formUse.name"></v-text-field>
                <v-text-field label="Email" :rules="rules.required" v-model="formUse.email"></v-text-field>
                <v-text-field label="Nomor HP / WA" :rules="rules.required" v-model="formUse.phone"></v-text-field>
                <p>Tanggal kadaluarsa membership</p>
                <v-date-picker v-model="formUse.expired"></v-date-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Gunakan Member</v-btn>
                <v-btn @click="dialogUse = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'user.index',
  metaInfo: { title: 'List Pengguna' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogCreate: false,
      dialogEdit: false,
      dialogBlock: false,
      dialogUse: false,
      showPass: false,
      showPassConfirm: false,
      moment,
      pagination: 'index',
      errors: [],
      storeErrors: [],
      updateErrors: [],
      destroyErrors: [],
      useErrors: [],
      formStore: {},
      formEdit: {},
      formUse: {},
      formBlock: {},
      formSearch: {
        keyword: ''
      },
      members: [],
      used: null,
      type: null,
      memberDetail: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
        emailRules: [
          v => !!v || "E-mail harus diisi",
          v => /.+@.+\..+/.test(v) || "Masukkan email yang valid"
        ]
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Member',
          to: '/member',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getMembers(page = 1){
      this.loading = true
      this.pagination = 'index'
      this.type = null
      await axios.get(`/member?page=${page}`, this.config).then(res => {
        this.members = res.data.data.members
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              return this.$router.push('/404')
            }
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async showUsed(page = 1){
      this.loading = true
      this.pagination = 'used'
      await axios.get(`/member/used/${this.type}?page=${page}`, this.config).then(res => {
        this.members = res.data.data.members
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              return this.$router.push('/404')
            }
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async searchMember(page = 1){
      this.loading = true
      if(this.formSearch.keyword.length < 1){
        this.getMembers()
      }else{
        this.pagination = 'search'
        await axios.get(`/member/search/${this.formSearch.keyword}?page=${page}`, this.config).then(res => {
          this.members = res.data.data.members
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if (e.response.status === 500){
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }else{
              if(e.response.data){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
        this.loading = false
      }
    },
    async storeMember(){
      this.loading = true
      this.storeErrors = []
      await axios.post('/member', this.formStore, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan member!')
        this.dialogCreate = false
        this.$refs.formStore.reset()
        this.$refs.formStore.resetValidation()
        this.getMembers()
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan member')
        if(e.response){
          console.log(e.response);
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateMember(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/member/${this.formEdit.id}`, this.formEdit, this.token).then(res => {
        this.$toast.success('Berhasil update member!')
        this.dialogEdit = false
        this.$refs.formEdit.reset()
        this.$refs.formEdit.resetValidation()
        this.getMembers()
      }).catch(e => {
        this.$toast.warning('Gagal update member')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateUseMember(){
      this.loading = true
      this.useErrors = []
      await axios.put(`/member/${this.formUse.id}`, this.formUse, this.token).then(res => {
        this.$toast.success('Member berhasil digunakan!')
        this.dialogUse = false
        this.$refs.formUse.reset()
        this.$refs.formUse.resetValidation()
        this.getMembers()
      }).catch(e => {
        this.$toast.warning('Member gagal digunakan')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.useErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            if(e.response.data){
              this.useErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    showMember(member){
        this.memberDetail = {...member}
        this.dialogShow = true
    },
    editMember(member){
      this.formEdit = {...member}
      this.dialogEdit = true
    },
    useMember(member){
      this.formUse = {...member}
      this.dialogUse = true
    },
  },
  created(){
    this.getMembers()
  }
}
</script>