<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card class="mt-9" :loading="loading" :disabled="loading">
          <v-card-title>Edit Artikel</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="updatePost()" ref="form">
              <v-text-field v-model="form.title" label="Judul Artikel" :rules="rules.required"></v-text-field>
              <v-text-field v-model="form.meta_desc" label="Meta Desc" :rules="rules.required"></v-text-field>
              <v-text-field v-model="form.meta_keywords" label="Meta Keywords" :rules="rules.required"></v-text-field>
              <label for="content">Isi Konten</label>
              <v-select label="Kategori" :items="categories" item-text="name" item-value="id" v-model="form.category_id"></v-select>
              <ckeditor :editor-url="editorUrl" id="content" v-model="form.content" :config="editorConfig" ></ckeditor>
              <v-row class="mt-5 mb-5">
                <v-col cols="8">
                  <v-text-field v-model="form.image" label="Link Gambar" :rules="rules.required"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn color="light-blue" dark @click="imageFileManager()">Buka Gambar</v-btn>
                </v-col>
                <v-col cols="12">
                  <p>Preview gambar</p>
                  <v-img :src="form.image" width="340px"></v-img>
                </v-col>
              </v-row>
              <v-switch v-model="form.publish" label="Publish"></v-switch>
              <v-btn type="submit" color="primary">Update</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from "vue";
import CKEditor from "ckeditor4-vue";

Vue.use(CKEditor);

export default {
  name: 'post.create',
  metaInfo: { title: 'Buat Artikel' },
  data(){
    return {
      skeleton: true,
      loading: false,
      categories: {},
      errors: [],
      form: {},
      editorUrl: "https://cdn.ckeditor.com/4.16.0/full/ckeditor.js",
      editorConfig: {
        removePlugins: "exportPdf",
      },
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Artikel',
          to: '/artikel',
          disabled: false,
          exact: true
        },
        {
          text: 'Edit Artikel',
          to: '#!',
          disabled: false,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
    id(){
      return this.$route.params.id
    }
  },
  methods: {
    async showPost(){
      this.loading = true
      this.errors = []
      await axios.get(`/post/${this.id}`, this.config).then(res => {
        this.form = res.data.data.post
        if(this.form.publish == true || this.form.publish == true || this.form.publish == 1 || this.form.publish == '1'){
          this.form.publish = true 
        }else{
          this.form.publish = false
        }
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
      this.skeleton = false
    },
    async getAllCategories(){
      this.loading = true
      this.errors = []
      await axios.get('/category/all', this.config).then(res => {
        this.categories = res.data.data.categories
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan artikel')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
      this.skeleton = false
    },
    async updatePost(){
      this.loading = true
      this.errors = []
      await axios.put(`/post/${this.id}`, this.form, this.config).then(res => {
        this.$toast.success('Berhasil update artikel!')
        this.$router.push({ path: `/artikel` })
      }).catch(e => {
        this.$toast.warning('Gagal update artikel')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    imageFileManager() {
      const routeData = this.$router.resolve({ name: "admin.image.index" });
      window.open(routeData.href, "", "width=1080,height=720");
    },
  },
  created(){
    this.getAllCategories()
    this.showPost()
  }
}
</script>