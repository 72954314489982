<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <h1>Edit Booking</h1>
        <v-card class="mt-9" :loading="loading" :disabled="loading">
          <v-card-text>
            <v-simple-table class="mb-9">
              <tbody>
                <tr>
                  <td>Invoice</td>
                  <td>:</td>
                  <td>{{ booking.invoice }}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>{{ moment(booking.date).locale('id').format('LL') }}</td>
                </tr>
                <tr>
                  <td>Pembuat Booking</td>
                  <td>:</td>
                  <td>{{ booking.user.name  }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{{ booking.user.email }}</td>
                </tr>
                <tr>
                  <td>Jumlah Tiket</td>
                  <td>:</td>
                  <td>{{ booking.amount }}</td>
                </tr>
                <tr>
                  <td>Total Harga</td>
                  <td>:</td>
                  <td>Rp. {{ booking.price.toLocaleString('id') }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>{{ booking.status }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <h2 class="mb-4">Ubah Status Booking</h2>
            <v-form @submit.prevent="updateBooking()" ref="formUpdate">
              <v-select :items="status" label="Status" v-model="formUpdate.status"></v-select>
              <v-btn type="submit" color="primary">Update</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'booking.edit',
  metaInfo: { title: 'Edit Booking' },
  data(){
    return {
      skeleton: true,
      loading: false,
      moment,
      errors: [],
      formUpdate: {},
      booking: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      status: ['pending', 'finish', 'cancel'],
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Booking',
          to: '/booking',
          disabled: false,
          exact: true
        },
        {
          text: 'Edit Booking',
          to: '#!',
          exact: true,
          disabled: true,
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
    id(){
      return this.$route.params.id
    }
  },
  methods: {
    async showBooking(){
      this.loading = true
      await axios.get(`/booking/${this.id}`, this.config).then(res => {
        this.booking = res.data.data.booking
        this.formUpdate.status = res.data.data.booking.status
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async updateBooking(){
      this.loading = true
      this.errors = []
      await axios.put(`/booking/${this.id}`, this.formUpdate, this.token).then(res => {
        this.$toast.success('Berhasil update tiket!')
        this.$router.push({ path: `/booking/detail/${this.booking.id}/${this.booking.invoice}` })
      }).catch(e => {
        this.$toast.warning('Gagal update tiket')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
  },
  created(){
    this.showBooking()
  }
}
</script>