<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h1>Detail Booking</h1>
          </v-card-title>
          <v-card-text>

            <v-alert type="warning" v-if="errors.length > 0">
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-alert>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Invoice</td>
                  <td>:</td>
                  <td>{{ booking.invoice }}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>{{ moment(booking.date).locale('id').format('LL') }}</td>
                </tr>
                <tr>
                  <td>Pembuat Booking</td>
                  <td>:</td>
                  <td>{{ booking.user.name  }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{{ booking.user.email }}</td>
                </tr>
                <tr>
                  <td>Jumlah Tiket</td>
                  <td>:</td>
                  <td>{{ booking.amount }}</td>
                </tr>
                <tr>
                  <td>Total Harga</td>
                  <td>:</td>
                  <td>Rp. {{ booking.price.toLocaleString('id') }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    <v-chip v-if="booking.status === 'pending'" color="light-blue lighten-1" dark>Pending</v-chip>
                    <v-chip v-if="booking.status === 'finish'" color="green lighten-1" dark>Selesai</v-chip>
                    <v-chip v-if="booking.status === 'cancel'" color="red" dark>Dibatalkan</v-chip>
                    <v-chip v-if="booking.status === 'used'" color="blue-grey" dark>Sudah Dipakai</v-chip>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="booking.status == 'finish'">
                <tr>
                  <td>Pembayaran</td>
                  <td>:</td>
                  <td>{{ booking.payment }}</td>
                </tr>
                <tr>
                  <td>Token</td>
                  <td>:</td>
                  <td>{{ booking.token }}</td>
                </tr>
                <tr>
                  <td>Qr Code</td>
                  <td>:</td>
                  <td>
                    <vrcode :value="booking.token" :options="{ size: 150 }"></vrcode>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div v-if="booking.status == 'finish'" class="mt-6">
              <v-btn color="light-blue" large dark @click="dialogSend = true">Kirim Ulang Tiket</v-btn>
              <v-dialog v-model="dialogSend" width="360px" max-width="94%">
                <v-card>
                  <v-card-title>Kirim ulang tiket?</v-card-title>
                  <v-card-text>
                    <p>Kirim ulang tiket {{ booking.invoice }}</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="grey lighten-2" large @click="dialogSend = false">Batal</v-btn>
                    <v-btn color="success" class="ml-4" large @click="resendTicket()">Kirim</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-text>
        </v-card>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import vrcode from '@ispa.io/vrcode'

export default {
  name: 'cs.booking.show',
  metaInfo: { title: 'Detail Booking' },
  components: { vrcode },
  data(){
    return {
      skeleton: true,
      loading: false,
      moment,
      dialogSend: false,
      errors: [],
      booking: null,
      items: [
        {
          text: 'Dashboard',
          to: '/cs/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Booking',
          to: '/cs/booking',
          disabled: false,
          exact: true
        },
        {
          text: 'Detail Booking',
          to: '#!',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
    id(){
      return this.$route.params.id
    }
  },
  methods: {
    async showBooking(){
      this.loading = true
      await axios.get(`/cs/booking/${this.id}`, this.config).then(res => {
        this.booking = res.data.data.booking
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
      this.skeleton = false
    },
    async resendTicket(){
      this.loading = true
      this.errors = []
      await axios.get(`/cs/booking/confirm/resend/${this.id}`, this.config).then(res => {
        this.$toast.success('Tiket berhasil dikirim ulang')
      }).catch(e => {
        this.$toast.warning('Tiket gagal dikirim ulang')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.dialogSend = false
      this.loading = false

    }
  },
  created(){
    this.showBooking()
  }
}
</script>