<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <h1>Tambah Booking</h1>
        <v-card flat :loading="loading" :disabled="loading">
          <v-card-text>
            <label for="type" class="mt-10">Silahkan Pilih Wahana</label>
            <select id="type" class="myform-control mt-4" @change="setTicket($event)">
              <option selected disabled>--- Pilih Tiket ---</option>
              <option value="0">Dinoland</option>
              <option value="1">Playground</option>
            </select>
            <v-simple-table class="mt-6" v-if="tickets">
              <thead>
                <tr>
                  <th>Tanggal</th>
                  <th>Harga</th>
                  <th>Keterangan</th>
                  <th>Sisa Kuota</th>
                  <th>Buat Booking</th>
                </tr>
              </thead>
              <i v-if="Object.keys(tickets).length < 1">Tiket tidak ada</i>
              <tbody v-else>
                <tr v-for="(ticket, i) in tickets" :key="i">
                  <td>{{ moment(ticket.date).locale('id').format('LL') }}</td>
                  <td v-if="ticket.price || ticket.price > 0">Rp. {{ ticket.price.toLocaleString('id') }}</td>
                  <td v-else><i>Harga mengikuti harga utama</i></td>
                  <td v-if="ticket.desc">{{ ticket.desc }}</td>
                  <td v-else>-</td>
                  <td>{{ ticket.remain }}</td>
                  <td>
                    <v-btn link :to="`/booking/tambah/${ticket.id}/${ticket.type}/${moment(ticket.date).locale('id').format('D-M-Y')}`" color="light-green" depressed small dark :disabled="ticket.remain < 1"><v-icon left>shopping_cart</v-icon> Buat Booking</v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'booking.create',
  metaInfo: { title: 'Tambah Booking' },
  data(){
    return {
      skeleton: true,
      loading: false,
      moment,
      tipes: ['dinoland', 'playground'],
      errors: [],
      tickets: null,
      dinoland_tickets: null,
      playground_tickets: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Booking',
          to: '/booking',
          disabled: false,
          exact: true
        },
        {
          text: 'Tambah Booking',
          to: '/booking/tambah',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getTickets(){
      this.loading = true
      await axios.get(`/booking/create`, this.config).then(res => {
        this.dinoland_tickets = res.data.data.dinoland_tickets
        this.playground_tickets = res.data.data.playground_tickets
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async showTicketPlayground(id){
      this.loading = true
      this.ticketDetail = null
      await axios.get(`/ticket/${id}`, this.config).then(res => {
        this.playground_ticket = res.data.data.ticket
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    setTicket(event){
      this.loading = true
      if(event.target.value == 0 || event.target.value == "0"){
        this.tickets = {...this.dinoland_tickets}
      }else{
        this.tickets = {...this.playground_tickets}
      }
      this.loading = false
    },
  },
  created(){
    this.getTickets()
  }
}
</script>

<style scoped>
.myform-control{
  display: block;
  width: 100%;
  height: 47px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: #000 ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: #000 ease-in-out .15s,#000 ease-in-out .15s;
  transition: #000 ease-in-out .15s,#000 ease-in-out .15s;
}
</style>