<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-card flat :loading="loading" :disabled="loading">
          <v-alert type="warning" v-if="errors.length > 0">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-alert>
          <h1>List Booking</h1>
          <v-btn color="light-blue" dark class="mt-5" @click="dialogCreate = true"><v-icon>file_upload</v-icon> Upload Gambar</v-btn>
          <v-form class="mt-10" @submit.prevent="searchImage()">
            <v-row>
              <v-col cols="8" lg="9">
                <v-text-field v-model="formSearch.keyword" label="Masukkan keyword"></v-text-field>
              </v-col>
              <v-col cols="4" lg="3">
                <v-btn color="primary" type="submit"><v-icon left>search</v-icon> Cari</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <div class="mt-6">
            <v-row v-if="images.data.length > 0" >
              <v-col cols="6" md="4" lg="3" v-for="(image, i) in images.data" :key="i">
                <v-card flat>
                  <v-img width="100%" height="180px" :src="image.url"></v-img>
                  <p>{{ image.name }}</p>
                  <div class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab color="light-blue" dark small v-bind="attrs" v-on="on" @click="showImage(image.url)"><v-icon>visibility</v-icon></v-btn>
                      </template>
                      <span>Lihat Gambar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-3" fab color="primary" small v-bind="attrs" v-on="on" @click="copyUrl(image.url)"><v-icon>content_copy</v-icon></v-btn>
                      </template>
                      <span>Copy Link Gambar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab color="red" dark small v-bind="attrs" v-on="on" @click="dialogDeleteImage(image)"><v-icon>delete</v-icon></v-btn>
                      </template>
                      <span>Hapus Gambar</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <i v-else>Gambar tidak ada</i>
          </div>
          <div class="mt-4">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
            <pagination v-if="pagination == 'index'" :data="images" @pagination-change-page="getImages" align="right"></pagination>
            <pagination v-if="pagination == 'search'" :data="images" @pagination-change-page="searchImage" align="right"></pagination>
          </div>
        </v-card>
        <v-dialog width="90%" v-model="dialogShow">
          <v-card flat>
            <v-img v-if="imageDetails" :src="imageDetails" width="100%"></v-img>
            <v-btn class="mt-5" color="grey lighten-1" block @click="dialogShow = false">Kembali</v-btn>
          </v-card>
        </v-dialog>
        <v-dialog width="400px" max-width="95%" v-model="dialogDestroy">
          <v-card flat>
            <v-card-title>Hapus Gambar</v-card-title>
            <v-card-text>
              <p>Yakin hapus gambar ini?</p>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mt-5" color="red" dark @click="destroyImage()">Hapus</v-btn>
              <v-btn class="mt-5" color="grey" dark @click="dialogDestroy = false">Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCreate">
          <v-card flat>
            <v-card-title>Tambah Gambar</v-card-title>
            <v-form ref="formStore" @submit.prevent="storeImage()">
              <v-card-text>
                <v-alert type="warning" v-if="storeErrors.length > 0">
                  <ul>
                    <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <label for="image" class="mr-4">Pilih Gambar</label>
              <input type="file" id="image" ref="file" @change="handleImageUpload" accept="image/*" class="my-3"/>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="primary">Upload</v-btn>
                <v-btn @click="dialogCreate = false" dark color="grey darken-1">Batal</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'image.index',
  metaInfo: { title: 'List Gambar' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogCreate: false,
      dialogDestroy: false,
      errors: [],
      storeErrors: [],
      updateErrors: [],
      destroyErrors: [],
      sortErrors: [],
      formStore: {},
      formDestroy: {},
      formSearch: {
        keyword: ''
      },
      images: null,
      imageDetails: '',
      pagination: 'index',
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Gambar',
          to: '/gambar',
          disabled: true,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getImages(page = 1){
      this.loading = true
      this.pagination = 'index'
      await axios.get(`/image?page=${page}`, this.config).then(res => {
        this.images = res.data.data.images
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async searchImage(page = 1){
      this.loading = true
      this.pagination = 'search'
      if(this.formSearch.keyword.length < 1){
        this.getImages()
      }else{
        await axios.get(`/image/search/${this.formSearch.keyword}?page=${page}`, this.config).then(res => {
          this.images = res.data.data.images
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if(e.response.status === 422){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
      }
      window.scrollTo(0, 0);
      this.loading = false
    },
    async storeImage(){
      this.loading = true
      this.storeErrors = []
      let formData = new FormData();
      formData.append('image', this.formStore.image);
      await axios.post('/image', formData, this.config).then(res => {
        this.$toast.success('Berhasil upload gambar!')
        this.dialogCreate = false
        this.formStore = {}
        this.$refs.formStore.reset()
        this.$refs.formStore.resetValidation()
        this.getImages()
      }).catch(e => {
        this.$toast.warning('Gagal upload gambar')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.storeErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.storeErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async destroyImage(){
      this.loading = true
      this.destroyErrors = []
      await axios.delete(`/image/${this.formDestroy.id}`, this.token).then(res => {
        this.$toast.warning('Gambar telah dihapus')
        this.dialogDestroy = false
        this.getImages()
      }).catch(e => {
        this.$toast.warning('Gagal hapus gambar')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.destroyErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.destroyErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }

      })
      this.loading = false
    },
    handleImageUpload(){
      this.formStore.image = this.$refs.file.files[0];
    },
    dialogDeleteImage(image){
      this.formDestroy = {...image}
      this.dialogDestroy = true
    },
    copyUrl(url){
      try {
        const urlText = document.createElement("textarea");
        urlText.value = url;
        document.body.appendChild(urlText);
        urlText.select();
        document.execCommand("copy");
        document.body.removeChild(urlText);
        this.$toast.success('Link gambar berhasil disalin ke clipboard')
      } catch (e) {
        this.$toast.warning('Link gambar berhasil disalin ke clipboard')
        console.log(e);
      }
    },
    showImage(url){
      this.imageDetails = url
      this.dialogShow = true
    }
  },
  created(){
    this.getImages()
  }
}
</script>