<template>
  <v-app>
    <Navbar  v-if="$router.currentRoute.name.startsWith('admin') || $router.currentRoute.name.startsWith('cs')"/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueMeta from 'vue-meta'
import Navbar from '@/components/Navbar.vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css';
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.use(VueMeta)
Vue.use(VueToast, {
  position: 'top-right',
  duration: 6000,
})

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('qr-code', VueQRCodeComponent)

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: `%s - Garut Dinoland`
  },
  components: {Navbar},

  data: () => ({
    //
  }),
};
</script>
