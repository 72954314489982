<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <h1>Tambah Booking</h1>
        <v-card class="mt-9" :loading="loading" :disabled="loading" v-if="ticket">
          <v-card-text>
            <h2 class="mb-5">Silahkan Isi Form Berikut</h2>
            <p>Tanggal Booking : {{ moment(ticket.date).locale('id').format('LL') }}</p>
            <p>Sisa Tiket : {{ ticket.remain }} tiket</p>
            <v-form @submit.prevent="storeBooking()" ref="formStore">
              <v-text-field v-model="formStore.email" label="Email" :rules="rules.required" outlined></v-text-field>
              <i>*Email harus valid, karena tiket dan invoice akan dikirim ke email</i>
              <div class="mt-7"></div>
              <v-text-field v-model="formStore.amount" label="Jumlah Tiket" :rules="rules.required" type="numeric" outlined></v-text-field>
              <v-btn type="submit" color="primary">Simpan</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <div v-else class="mt-12">
          <v-btn block color="blue-grey" dark link to="/cs/booking/tambah">Kembali</v-btn>
        </div>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'cs.booking.store',
  metaInfo: { title: 'Buat Booking' },
  data(){
    return {
      skeleton: true,
      loading: false,
      moment,
      tipes: ['dinoland', 'playground'],
      errors: [],
      formStore: {},
      booking: null,
      ticket: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/cs/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Booking',
          to: '/cs/booking',
          disabled: false,
          exact: true
        },
        {
          text: 'Tambah Booking',
          to: '/cs/booking/tambah',
          disabled: false,
          exact: true
        },
        {
          text: 'Buat Booking',
          to: '#!',
          exact: true,
          disabled: true,
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
    id(){
      return this.$route.params.id
    },
  },
  methods: {
    async showTicket(){
      this.loading = true
      await axios.get(`/cs/ticket/${this.id}`, this.config).then(res => {
        this.ticket = res.data.data.ticket
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.skeleton = false
      this.loading = false
    },
    async storeBooking(){
      this.loading = true
      this.storeErrors = []
      this.formStore.ticket_id = this.id
      await axios.post('/cs/booking', this.formStore, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan tiket!')
        this.$router.push({ path: `/cs/booking/detail/${res.data.data.booking.id}/${res.data.data.booking.invoice}` })
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan tiket')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
        this.loading = false
      })
    },
  },
  created(){
    this.showTicket()
  }
}
</script>