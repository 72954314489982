<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <h1 class="mb-5">List Promo</h1>
        <v-btn class="mb-5" link to="/promo/tambah" color="primary">Tambah Promo</v-btn>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Gambar</th>
                  <th>Judul Promo</th>
                  <th>Status Publish</th>
                  <th class="text-center">Aksi</th>
                </tr>
              </thead>
              <i v-if="promos.data.length < 1">Promo tidak ada</i>
              <tbody v-else>
                <tr v-for="(promo, i) in promos.data" :key="i">
                  <td>
                    <v-img width="100px" height="60px" :src="promo.image"></v-img>
                  </td>
                  <td>{{ promo.title }}</td>
                  <td>
                    <v-chip color="green" dark v-if="promo.publish == true || promo.publish == true || promo.publish == 1 || promo.publish == '1'">Aktif</v-chip>
                    <v-chip v-else color="grey lighten-2">Tidak Aktif</v-chip>
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showPromo(promo)"><v-icon>visibility</v-icon></v-btn>
                      </template>
                      <span>Detail</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="indigo" class="mx-2" v-bind="attrs" v-on="on" light :to="`/promo/edit/${promo.id}/${promo.slug}`"><v-icon>edit</v-icon></v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="promo.publish == true || promo.publish == true || promo.publish == 1 || promo.publish == '1'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab x-small color="grey lighten-2" class="mx-2" v-bind="attrs" v-on="on" @click="showPublishDialog(promo)"><v-icon>close</v-icon></v-btn>
                      </template>
                      <span>Non Aktifkan</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="showPublishDialog(promo)"><v-icon>check</v-icon></v-btn>
                      </template>
                      <span>Aktifkan</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
              <div class="mt-4">
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
                <pagination :data="promos" @pagination-change-page="getPromos" align="right"></pagination>
              </div>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog v-model="dialogShow" width="90%">
        <v-card v-if="promoDetail">
          <v-card-text>
            <v-img width="300px" :src="promoDetail.image"></v-img>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Judul</td>
                  <td>:</td>
                  <td>{{ promoDetail.title }}</td>
                </tr>
                <tr>
                  <td>Meta Desc</td>
                  <td>:</td>
                  <td>{{ promoDetail.meta_desc }}</td>
                </tr>
                <tr>
                  <td>Meta Keywords</td>
                  <td>:</td>
                  <td>{{ promoDetail.meta_keywords }}</td>
                </tr>
                <tr>
                  <td>Status Publish</td>
                  <td>:</td>
                  <td>
                    <v-chip color="green" dark v-if="promoDetail.publish == true || promoDetail.publish == 1 || promoDetail.publish == '1'">Aktif</v-chip>
                    <v-chip v-else color="grey lighten-2">Tidak Aktif</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p>Isi</p>
            <div v-html="promoDetail.content"></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" @click="dialogShow = false" dark>Kembali</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPublish" width="340px" max-width="95%">
        <v-card v-if="formPublish" :loading="loading">
          <v-card-title>
            <p>{{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'Nonaktifkan ' : 'Aktifkan '}} Promo</p>
          </v-card-title>
          <v-card-text>
            <p>Yakin {{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'nonaktifkan ' : 'aktifkan '}} promo ini?</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" @click="dialogPublish = false" dark>Kembali</v-btn>
            <v-btn color="primary" @click.prevent="publishPromo()" dark>{{ formPublish.publish  == true || formPublish.publish == 1 || formPublish.publish == '1' ? 'Nonaktifkan ' : 'Aktifkan '}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'promo.index',
  metaInfo: { title: 'Promo' },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogShow: false,
      dialogPublish: false,
      promos: null,
      promoDetail: null,
      formPublish: {},
      errors: [],
      messages: [],
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
        Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getPromos(){
      this.loading = true
      await axios.get('/promo', this.config).then(res => {
        this.promos = res.data.data.promos
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
      this.skeleton = false
    },
    async publishPromo(){
      this.loading = true
      await axios.put(`/promo/publish/${this.formPublish.id}`).then(res => {
        const publish = res.data.data.promo.publish 
        let message = ''
        if(publish == true || publish == true || publish == 1 || publish == '1'){
          message = 'Berhasil mengatifkan promo'
        }else{
          message = 'Berhasil menonaktifkan promo'
        }
        this.$toast.success(message)
        this.getPromos()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.dialogPublish = false
      this.loading = false
    },
    showPromo(promo){
      this.promoDetail = {...promo}
      this.dialogShow = true
    },
    showPublishDialog(promo){
      this.formPublish = {...promo}
      this.dialogPublish = true
    },

  },
  created(){
    this.getPromos()
  }
}
</script>

<style>

</style>