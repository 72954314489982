<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <h1 class="mb-5">Pengaturan Pengguna</h1>
        <v-row>
          <v-col cols="12" lg="6">
            <v-card :loading="loading" :disabled="loading">
              <v-card-title>
                Ubah Password
              </v-card-title>
              <v-card-text>
                <v-alert type="warning" v-if="errorsUpdatePass.length > 0">
                  <ul>
                    <li v-for="error in errorsUpdatePass" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-form ref="formUpdatePass" @submit.prevent="updatePassword()">
                    <v-text-field
                      v-model="formUpdatePass.current_password"
                      :append-icon="showOldPass ? 'visibility' : 'visibility_off'"
                      :rules="rules.required"
                      :type="showOldPass ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password Lama" 
                      @click:append="showOldPass = !showOldPass"
                    ></v-text-field>
                    <v-text-field
                      v-model="formUpdatePass.password"
                      :append-icon="showPass ? 'visibility' : 'visibility_off'"
                      :rules="rules.required"
                      :type="showPass ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password Baru" 
                      @click:append="showPass = !showPass"
                    ></v-text-field>
                    <v-text-field
                      v-model="formUpdatePass.password_confirmation"
                      :append-icon="showPassConfirm ? 'visibility' : 'visibility_off'"
                      :rules="rules.required"
                      :type="showPassConfirm ? 'text' : 'password'"
                      name="input-10-1"
                      label="Konfirmasi Password Baru" 
                      @click:append="showPassConfirm = !showPassConfirm"
                    ></v-text-field>
                    <v-btn type="submit" color="primary">Update Password</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card :loading="loading" :disabled="loading">
              <v-card-title>
                Ubah Profil
              </v-card-title>
              <v-card-text>
                <v-alert type="warning" v-if="errorsUpdatePass.length > 0">
                  <ul>
                    <li v-for="error in errorsUpdatePass" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
                <v-form ref="formUpdateProfile" @submit.prevent="updateProfile()">
                  <v-text-field v-model="formUpdateProfile.name" label="Nama" :rules="rules.required"></v-text-field>
                  <v-text-field v-model="formUpdateProfile.phone" label="Nomor Telpon" :rules="rules.required"></v-text-field>
                  <v-btn type="submit" color="primary">Update Profil</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'user.edit',
  metaInfo: { title: 'Pengaturan Pengguna' },
  data(){
    return {
      skeleton: true,
      loading: false,
      moment,
      errors: [],
      errorsUpdatePass: [],
      booking: null,
      formUpdatePass: {},
      formUpdateProfile: {},
      showOldPass: false,
      showPass: false,
      showPassConfirm: false,
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Pengaturan Pengguna',
          to: '#!',
          disabled: true,
          exact: true
        },
      ],
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
          },
        crossDomain: true,
      };
    },
    user(){
      return this.$store.state.auth.user
    }
  },
  methods: {
    ...mapActions(["auth/attempt"]),
    async updatePassword(){
      this.loading = true
      this.errorsUpdatePass = []
      await axios.put(`/auth/change-password`, this.formUpdatePass, this.config).then(res => {
        this.$toast.success('Password berhasil di ubah')
        this.$refs.formUpdatePass.reset()
        this.$refs.formUpdatePass.resetValidation()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if (e.response.status === 500){
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }else{
            if(e.response.data){
              this.errorsUpdatePass = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateProfile(){
      this.loading = true
      this.errorsUpdateProfile = []
      await axios.put(`/auth/change-profile`, this.formUpdateProfile, this.config).then(res => {
        this.$toast.success('Profil berhasil di ubah')
        this.$refs.formUpdateProfile.resetValidation()
        this.attempt(this.token)
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if (e.response.status === 500){
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }else{
            if(e.response.data){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    setProfile(){
      this.formUpdateProfile = {...this.user.user}
      this.loading = false
      this.skeleton = false
    }
  },
  created(){
    this.setProfile()
  }
}
</script>