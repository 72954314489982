<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card class="mt-9" :loading="loading" :disabled="loading">
          <v-card-title>
            Tambah Promo
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="storePromo()" ref="form">
              <v-text-field v-model="form.title" label="Judul Promo" :rules="rules.required"></v-text-field>
              <v-text-field v-model="form.meta_desc" label="Meta Desc" :rules="rules.required"></v-text-field>
              <v-text-field v-model="form.meta_keywords" label="Meta Keywords" :rules="rules.required"></v-text-field>
              <label for="content">Isi Konten</label>
              <ckeditor :editor-url="editorUrl" id="content" v-model="form.content" :config="editorConfig" ></ckeditor>
              <v-row class="mt-5 mb-5">
                <v-col cols="8">
                  <v-text-field v-model="form.image" label="Link Gambar" :rules="rules.required"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn color="light-blue" dark @click="imageFileManager()">Buka Gambar</v-btn>
                </v-col>
                <v-col cols="12">
                  <p>Preview gambar</p>
                  <v-img :src="form.image" width="340px"></v-img>
                </v-col>
              </v-row>
              <v-btn type="submit" color="primary">Simpan</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from "vue";
import CKEditor from "ckeditor4-vue";

Vue.use(CKEditor);

export default {
  name: 'promo.create',
  metaInfo: { title: 'Buat Promo' },
  data(){
    return {
      skeleton: true,
      loading: false,
      errors: [],
      form: {},
      editorUrl: "https://cdn.ckeditor.com/4.16.0/full/ckeditor.js",
      editorConfig: {
        removePlugins: "exportPdf",
      },
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
      items: [
        {
          text: 'Dashboard',
          to: '/dashboard',
          disabled: false,
          exact: true
        },
        {
          text: 'Promo',
          to: '/promo',
          disabled: false,
          exact: true
        },
        {
          text: 'Tambah Promo',
          to: '/promo/tambah',
          disabled: false,
          exact: true
        },
      ]
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async storePromo(){
      this.loading = true
      this.errors = []
      await axios.post('/promo', this.form, this.token).then(res => {
        this.$toast.success('Berhasil menyimpan promo!')
        this.$router.push({ path: `/promo` })
      }).catch(e => {
        this.$toast.warning('Gagal menyimpan promo')
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    imageFileManager() {
      const routeData = this.$router.resolve({ name: "admin.image.index" });
      window.open(routeData.href, "", "width=1080,height=720");
    },
  },
  created(){
    this.loading = false
    this.skeleton = false
  }
}
</script>